import React, { useState, useEffect } from 'react';
import sales from '../Sales';
import Navbar from '../Navbar/Navbar';
import ReactSpeedometer from "react-d3-speedometer";
import Plot from 'react-plotly.js';
import './Dashboard.css'
import './ChartStyles.css'
import Logo from './techdogOlogo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMedal } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import { useNavigate, useParams } from 'react-router-dom';

const getResponsiveDimensions = () => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const width = screenWidth > 1920 ? screenWidth * 0.4 : 250;
  const height = screenHeight > 1080 ? screenHeight * 0.3 : 225;

  return { width, height };
};

const Dashboard = () => {
  const cookies = new Cookies;
  const navigate = useNavigate();

  const { month, year } = useParams();

  const now = dayjs(`${year}-${month}`).locale('nl').format('MMMM');
  const [chartDimensions, setChartDimensions] = useState({ width: 250, height: 225 });
  const [teamTarget, setTeamTarget] = useState([]);
  const [bestSeller, setBestSeller] = useState([]);
  const [deals, setDeals] = useState([]);
  const [existantDeals, setExistantDeals] = useState([]);
  const [newDeals, setNewDeals] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [rankList, setRankList] = useState([]);
  const [soldServices, setSoldServices] = useState([]);
  const [xYearRankList, setXYearRankList] = useState([]);
  const [yYearRankList, setYYearRankList] = useState([]);

  function checkIfIntegerAddComma(number) {
    if (Number.isInteger(number)) {
      return number.toString().replace('.', ',');
    } else {
      return number.toFixed(2).toString().replace('.', ',');
    }
  }

  async function fetchAll(month, year, reload) {
    try {
      if (!reload) {
        setIsLoading(true);
      }

      let prevYear = 0;
      let prevMonth = 0;

      if (month == 1) {
        prevYear = year - 1;
        prevMonth = 12
      } else {
        prevYear = year;
        prevMonth = month - 1;
      }

      const [
        rankListResponse,
        teamTargetResponse,
        mostSoldProductsResponse,
        yearRankListResponse,
        bestSellerResponse,
        dealsResponse,
        existantDealsResponse,
        newDealsResponse
      ] = await Promise.all([
        sales.get(`rankList?month=${month}&year=${year}`),
        sales.get(`teamTarget?month=${month}&year=${year}`),
        sales.get(`mostSoldProducts?month=${month}&year=${year}`),
        sales.get(`yearRankList?month=${month}&year=${year}`),
        sales.get(`bestSeller?month=${prevMonth}&year=${prevYear}`),
        sales.get(`getDeals?month=${prevMonth}&year=${prevYear}`),
        sales.get(`getDeals?month=${month}&year=${year}&status=0`),
        sales.get(`getDeals?month=${month}&year=${year}&status=1`),
      ]);

      setSoldServices(mostSoldProductsResponse.data)
      setRankList(rankListResponse.data[0]);
      setTeamTarget(teamTargetResponse.data);
      setBestSeller(bestSellerResponse.data);
      setDeals(dealsResponse.data);
      setExistantDeals(existantDealsResponse.data);
      setNewDeals(newDealsResponse.data);

      setAmount(rankListResponse.data[1])
      
      if (teamTargetResponse.data.amount < rankListResponse.data[1]) {
        setTotalPrice(teamTargetResponse.data.amount);
      } else {
        setTotalPrice(rankListResponse.data[1]);
      }

      let names = [];
      let totalPrices = [];
      yearRankListResponse.data.forEach(user => {
        totalPrices.push(user.totalPrice);
        names.push(`${user.name} <br> € ${checkIfIntegerAddComma(user.totalPrice)} <br> ${user.date}`);
      });
      setXYearRankList(names);
      setYYearRankList(totalPrices)

      setIsLoading(false);
    } catch (error) {
      console.warn(error.response);
      cookies.remove('token', { path: '/' });
      navigate('/');
    }
  }

  const MINUTE_MS = 60000;

  useEffect(() => {
    if (cookies.get('token')) {
      fetchAll(month, year, false);

      const interval = setInterval(() => {
        fetchAll(month, year, true);
      }, MINUTE_MS);

      return () => clearInterval(interval);
    } else {
      navigate('/');
    }
  }, [month, year]);

const SpeedoMeterBox = () => {
  const [dimensions, setDimensions] = useState({
    width: Math.min(window.innerWidth * 0.4, 500),
    height: Math.min(window.innerHeight * 0.3, 300)
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: Math.min(window.innerWidth * 0.4, 500),
        height: Math.min(window.innerHeight * 0.3, 300)
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
<div className='SpeedoMeterBox p-0'>
  <ReactSpeedometer
    className='reactSpeed'
    width={window.innerWidth >= 2560 ? 525 : 400}  // Scale up for 4K
    height={window.innerWidth >= 2560 ? 315 : 250}  // Maintain aspect ratio
    minValue={0}
    maxValue={teamTarget.amount}
    needleHeightRatio={0.7}
    segments={7}
    needleColor={'black'}
    labelFontSize={'0'}
    startColor={'#67b7dc'}
    endColor={'#dc67ce'}
    value={teamTarget.amount ? totalPrice : 0}
    textColor={textColor}
  />
</div>
  );
};

  const textColor = '#ffffff';

  function capitalize(month) {
    return month.charAt(0).toUpperCase() + month.slice(1);
  }

  function nameLimiter(text) {
    return text.length >= 23 ? `${text.slice(0, 23)}...` : text
  }

  const yearRankListBox = [{
    x: xYearRankList,
    y: yYearRankList,
    type: "bar",
    marker: {
      color: '#2196F3'
    },
    preserveAspectRatio: "none"
  }];

  const yearRankListBoxLayout = {
    title: {
      font: {
        size: 20,
        family: '"Open Sans", verdana, arial, sans-serif'
      },
      y: 0.95
    },
    xaxis: {
      title: "",
      tickangle: -45,
      tickfont: {
        size: 11,
        family: '"Open Sans", verdana, arial, sans-serif'
      },
      automargin: false,
      showgrid: false
    },
    margin: {
      b: 120,
      l: 60,
      r: 20,
      t: 60
    },
    showlegend: false,
    yaxis: {
      showgrid: true,
      gridcolor: '#E1E1E1'
    },
  };

  const config = {
    displayModeBar: false
  };

  const MIN_BAR_WIDTH = 35;
  const maxDataRanklist = Math.max(...rankList.map((d) => d.totalPrice));
  const maxDataServices = Math.max(...soldServices.map((d) => d.totalPrice));
  const chartWidth = 250;
  const chartHeight = 225;
  const barHeightRanklist = chartHeight / rankList.length;
  const barHeightServices = chartHeight / soldServices.length;
  let barPadding = 0.5;

  if (rankList.length <= 5 || soldServices.length <= 5) {
    barPadding = 1.5;
  }

  const getChartStyles = () => ({
    nameText: {
      fontWeight: "bold",
      fontSize: "clamp(0.6rem, 0.25vw, 5.0rem)",
      textAnchor: "start",
    },
    valueText: {
      fontWeight: "bold",
      fontSize: "9.6px",
      fill: "#FFFFFF",
      textAnchor: "middle",
    },
  });

  const RanklistChart = () => {
    const styles = getChartStyles();
    const maxBarWidth = chartWidth * 0.5; // 125px max
    const startX = 125;
    const DEFAULT_WIDTH = 30; // Minimum default width for visibility
  
    return (
      <div className="chart-container position-relative">
        <svg width="100%" height="100%" viewBox="0 0 250 225" preserveAspectRatio="xMidYMid meet">
          {rankList.map((d, i) => {
            // Base calculation on proportion of max value
            const proportion = d.totalPrice / maxDataRanklist;
            // Scale the remaining width (after default) by proportion
            const scaledWidth = proportion * (maxBarWidth - DEFAULT_WIDTH);
            // Add default width to ensure visibility while maintaining proportions
            const barWidth = DEFAULT_WIDTH + scaledWidth;
            
            return (
              <g key={i}>
                <text
                  x="5"
                  y={i * (barHeightRanklist + barPadding) + barHeightRanklist / 2}
                  alignmentBaseline="middle"
                  style={styles.nameText}
                >
                  {nameLimiter(d.name)}
                </text>
  
                <rect
                  x={startX}
                  y={i * (barHeightRanklist + barPadding) + barPadding / 2}
                  width={barWidth}
                  height={barHeightRanklist}
                  fill="#69b3a2"
                />
  
                <text
                  x={startX + barWidth / 2}
                  y={i * (barHeightRanklist + barPadding) + barHeightRanklist / 2}
                  alignmentBaseline="middle"
                  style={styles.valueText}
                >
                  €{checkIfIntegerAddComma(d.totalPrice)}
                </text>
              </g>
            );
          })}
        </svg>
      </div>
    );
  };
  
  const MostSoldProductsChart = () => {
    const styles = getChartStyles();
    const maxBarWidth = chartWidth * 0.5;
    const startX = 125;
    const DEFAULT_WIDTH = 30;
  
    return (
      <div className="chart-container position-relative">
        <svg width="100%" height="100%" viewBox="0 0 250 225" preserveAspectRatio="xMidYMid meet">
          {soldServices.map((d, i) => {
            const proportion = d.totalPrice / maxDataServices;
            const scaledWidth = proportion * (maxBarWidth - DEFAULT_WIDTH);
            const barWidth = DEFAULT_WIDTH + scaledWidth;
  
            return (
              <g key={i}>
                <text
                  x="5"
                  y={i * (barHeightServices + barPadding) + barHeightServices / 2}
                  alignmentBaseline="middle"
                  style={styles.nameText}
                >
                  {nameLimiter(d.name)}
                </text>
  
                <rect
                  x={startX}
                  y={i * (barHeightServices + barPadding) + barPadding / 2}
                  width={barWidth}
                  height={barHeightServices}
                  fill="#69b3a2"
                />
  
                <text
                  x={startX + barWidth / 2}
                  y={i * (barHeightServices + barPadding) + barHeightServices / 2}
                  alignmentBaseline="middle"
                  style={styles.valueText}
                >
                  €{checkIfIntegerAddComma(d.totalPrice)}
                </text>
              </g>
            );
          })}
        </svg>
      </div>
    );
  };

  return (
    <div className='bodyDashboard flex flex-col '>
      <Navbar />
      {!isLoading ? (
        <div className='box-container d-flex flex-column'>

          {/* Top row - Three main boxes */}
          <div className='top-row-container row column-lg gap-3 m-3 gap-lg-3'>
            {/* Ranglijst Box */}
            <div className='midbox col-12 col-md order-2 order-lg-1 rounded-2 p-3'>
              <div className='d-flex justify-content-center'>
                <h5 id='rankList' className='heading-primary'>Ranglijst van {capitalize(now)}</h5>
              </div>
              <div className='midBoxCharts d-flex justify-content-center pb-1'>
                <RanklistChart />
              </div>

            </div>

            {/* Team Target Box */}
            <div className='d-flex justify-content-between text-center row m-0 col-12 col-lg order-1 order-lg-2 bigbox rounded-2 p-3 '>
              <div className='text-center'>
                <h5 id='teamTarget' className='heading-primary'>Team target van {capitalize(now)}</h5>
                <p className='fw-bold heading-secondary'>Target: € {teamTarget.amount}</p>
              </div>

<SpeedoMeterBox></SpeedoMeterBox>

              <div className='d-flex justify-content-center gap-4 mt-3'>
                <p className='box-heading value-display'>Bedrag: € {checkIfIntegerAddComma(amount)}</p>
                <p className='box-heading value-display'>Te gaan: € {teamTarget.amount ? checkIfIntegerAddComma(teamTarget.amount - totalPrice) : 0}</p>
              </div>

              <div className="mt-auto pt-4 border-top">
                <h4 className="heading-secondary text-center mb-2 text-gray-600">
                  Wat we gaan doen als we het target halen:
                </h4>
                <h4 className="box-heading text-center fw-bold">
                  {teamTarget.text}
                </h4>
              </div>
            </div>

            {/* Verkochte Diensten Box */}
            <div className='midbox rounded-2 col-12 col-md order-3 rounded-2 p-3'>
              <div className='d-flex justify-content-center'>
                <h5 id='soldServices' className='heading-primary'>Verkochte diensten in {capitalize(now)}</h5>
              </div>
              <div className='midBoxCharts d-flex justify-content-center pb-1'>
                <MostSoldProductsChart />
              </div>
            </div>
          </div>

          {/* Bottom row - Chart and info boxes */}
          <div className='row column-lg gap-3 m-3 mt-0'>


            {/* Year Chart Box */}
            <div className='yearly-chart-container bg-white rounded-2 col-12 col-md-12 col-lg-8 col-lg p-3'>
              <div className='chart-header d-flex justify-content-center p-2'>
                <h4 className='heading-primary'>Ranglijst afgelopen 12 maanden</h4>
              </div>
              <div className='chart-wrapper'>
                <Plot
                  data={yearRankListBox}
                  layout={yearRankListBoxLayout}
                  config={{
                    displayModeBar: false
                  }}
                  className="responsive-plot h-100"
                  useResizeHandler={true}
                />
              </div>
            </div>

            {/* Right side small boxes */}
            <div className='row col-12 col-md-12 mx-lg-0 col-lg p-0 no-padding-left-no-right gap-lg-0 gap-md-3 p-0 g-0 gap-3'>
              {/* First row */}
              <div className='col-12 col-md col-lg-6 p-0 custom-padding-end pb-lg-3'>
                <div className='smallboxes rounded-2 p-2 d-flex text-center flex-column'>
                  <p className='box-heading mb-2'>Beste verkoper van de afgelopen maand</p>
                  <h3 className='sellerofthemonth mb-0'>{bestSeller.name} <FontAwesomeIcon icon={faMedal} className="icon" /></h3>
                </div>
              </div>

              {/* Second row */}
              <div className='col-12 col-md col-lg-6 p-0 custom-padding-start pb-lg-3'>
                <div className='smallboxes rounded-2 p-2 d-flex text-center flex-column'>
                  <p className='box-heading mb-2'>Totaal vorige maand</p>
                  <h4 className='box-value color-blue mb-2'>€ {checkIfIntegerAddComma(deals.totalPrice)}</h4>
                  <div className='d-flex justify-content-around'>
                    <div>
                      <p className='label-small mb-1'>Deals</p>
                      <h5 className='value-small mb-0'>{deals.totalDeals}</h5>
                    </div>
                    <div>
                      <p className='label-small mb-1'>Gem. order</p>
                      <h5 className='value-small mb-0'>€ {checkIfIntegerAddComma(deals.averagePrice)}</h5>
                    </div>
                  </div>
                </div>
              </div>

              {/* Third row */}
              <div className='col-12 col-md col-lg-6 p-0 custom-padding-end'>
                <div className='smallboxes rounded-2 p-2 d-flex text-center flex-column'>
                  <p className='box-heading mb-2'>Totaal nieuwe deals</p>
                  <h4 className='box-value mb-2'>€ {checkIfIntegerAddComma(newDeals.totalPrice)}</h4>
                  <div className='d-flex justify-content-around'>
                    <div>
                      <p className='label-small mb-1'>Deals</p>
                      <h5 className='value-small mb-0'>{newDeals.totalDeals}</h5>
                    </div>
                    <div>
                      <p className='label-small mb-1'>Gem. order</p>
                      <h5 className='value-small mb-0'>€ {checkIfIntegerAddComma(newDeals.averagePrice)}</h5>
                    </div>
                  </div>
                </div>
              </div>

              {/* Fourth row */}
              <div className='col-12 col-md col-lg-6 p-0 custom-padding-start'>
                <div className='smallboxes rounded-2 p-2 d-flex text-center flex-column'>
                  <p className='box-heading mb-2'>Totaal bestaande deals</p>
                  <h4 className='box-value mb-2'>€ {checkIfIntegerAddComma(existantDeals.totalPrice)}</h4>
                  <div className='d-flex justify-content-around'>
                    <div>
                      <p className='label-small mb-1'>Deals</p>
                      <h5 className='value-small mb-0'>{existantDeals.totalDeals}</h5>
                    </div>
                    <div>
                      <p className='label-small mb-1'>Gem. order</p>
                      <h5 className='value-small mb-0'>€ {checkIfIntegerAddComma(existantDeals.averagePrice)}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>{/* bottom row boxes*/}

        </div>
      ) : (
        <div className='mt-5 d-flex justify-content-center'>
          <div className="custom-spinner">
            <img
              className='img-fluid'
              src={Logo}
              alt="Loading"
              style={{ animation: 'spin 0.7s linear infinite' }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
